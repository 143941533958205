<template>
    <div>
        <van-grid :column-num="3">
            <van-grid-item icon="balance-list-o" v-if="hasPermission56" text="录入回款" to="/tiantianquan/huikuan">
                <template #default>
                    <van-icon name="balance-list-o" size="60" />
                    <div class="f-s-16">录入回款</div>
                </template>
            </van-grid-item>
        </van-grid>
        <van-empty v-if="!hasPermission56" description="暂无权限"></van-empty>
    </div>
</template>

<script>
import api from "@/js/api";

export default {
    name: "tiantianquan-index",
    data(){
        return {

        }
    },
    computed: {
        hasPermission56() {
            return this.hasPermission(56)
        }
    },
    methods: {
        hasPermission(menuId) {
            return !!this.vuex_menus.find((item) => item.MenuId == menuId)
        },
    },
    created() {

    }
}
</script>

<style scoped>

</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../layout/index.vue'
import TiantianquanIndex from '../views/tiantianquan/index.vue'
import HuikuanIndex from '../views/tiantianquan/huikuan/index.vue'
import HuikuanForm from '../views/tiantianquan/huikuan/form.vue'

Vue.use(VueRouter)

const routes = [
    {path: '/', redirect: '/tiantianquan'},
    {path: '/', component: Home, children: [
        {path: '/tiantianquan', name: 'TiantianquanIndex', component: TiantianquanIndex, meta: {title: '田田圈资金管理'}},
        {path: '/tiantianquan/huikuan', name: 'HuikuanIndex', component: HuikuanIndex, meta: {title: '回款列表'}},
        {path: '/tiantianquan/huikuan/form', name: 'HuikuanForm', component: HuikuanForm, meta: {title: '回款录入表单'}},
    ]}
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

router.beforeEach((to, from, next) => {
    // 设置标题
    document.title = to.meta?.title || '诺普信'
    next()
})

export default router

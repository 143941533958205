<template>
    <div>
        <router-view/>
    </div>
</template>

<script>
import api from "@/js/api";

export default {
    name: "HomeView",
    methods: {
        getMenus(){
            api.getWebService('WebS_MenuRight', this.vuex_emp_no).then(data => {
                this.$vuex('vuex_menus', data.Table)
            })
        }
    },
    created() {
        let empId = this.$route.query.empId
        if (process.env.NODE_ENV === 'development') {
            console.log('----开发环境');
            empId = 16378
        }
        if (!empId) {
            let wxOauthUrl = 'http://webapp.noposion.com/prod-api/auth/wx/work/wx95038f218c8d8671/1000116/oauth?redirect=http%3A%2F%2Fzj.noposion.com%2F'
            location.replace(wxOauthUrl)
            return
        }
        this.$vuex('vuex_emp_no', empId)
        this.getMenus()
    }
}
</script>

<style scoped>

</style>

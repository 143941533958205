import { mapState } from 'vuex'
import store from "@/store"

// 尝试将用户在根目录中的store/index.js的vuex的state变量，全部加载到全局变量中
let $uStoreKey = [];
try{
	$uStoreKey = store.state ? Object.keys(store.state) : [];
}catch(e){

}

export default {
	beforeCreate() {
		// 将vuex方法挂在到$u中
		// 使用方法为：如果要修改vuex的state中的user.name变量为"史诗" => this.$u.vuex('user.name', '史诗')
		// 如果要修改vuex的state的version变量为1.0.1 => this.$u.vuex('version', '1.0.1')
		this.$vuex = (name, value) => {
			this.$store.commit('$upStore', {
				name,value
			})
		}

	},
	onLoad(){
		// 可以在这里设置小程序全局默认的转发参数
	},
	methods: {
		$dateFormat(time, fmt = 'yyyy-MM-dd hh:mm:ss') {
			if(typeof time == 'number') {
				if(time < 10000000000) { // 秒时间戳
					time = time * 1000
				}
				return new Date(time).format(fmt)
			}else if(time instanceof Date) {
				return time.format(fmt)
			}
			return new Date(time).format(fmt)
		},
	},
	computed: {
		// 将vuex的state中的所有变量，解构到全局混入的mixin中
		...mapState($uStoreKey)
	}
}

<template>
<div class="p-12 p-b-100">
    <van-form ref="vanForm" :model="form" :show-error="false" class="bor-ra-8" style="overflow: hidden">
        <van-field label="回款日期" name="BillDate" placeholder="请选择回款日期" right-icon="arrow" :value="form.BillDate.format('yyyy-MM-dd')"
                   required :rules="[{required: true, message: '请选择回款日期'}]"
                   readonly @click="showBillDate = true" />
        <van-calendar v-model="showBillDate" :min-date="calenderMinDate" :max-date="calenderMaxDate" :default-date="form.BillDate" @confirm="onBillDateConfirm" />

        <van-field label="收款金额" name="SkMoney" placeholder="请填写收款金额" type="number" v-model="form.SkMoney"
                   required :rules="[{required: true, message: '请输入收款金额'}]"/>
        <van-field label="收款账号" name="SkaccountN" placeholder="请选择收款账号" right-icon="arrow" :value="form.SkaccountN"
                   required :rules="[{required: true, message: '请选择收款账号'}]"
                   readonly @click="showSkAccountPicker = true" />
        <van-popup v-model="showSkAccountPicker" position="bottom">
            <van-picker show-toolbar :columns="skAccountList" value-key="Name" @confirm="onSkAccountConfirm" @cancel="showSkAccountPicker = false" />
        </van-popup>
        <van-field label="款项类型" name="SktypeN" placeholder="请选择款项类型" right-icon="arrow" :value="form.SktypeN"
                   required :rules="[{required: true, message: '请选择款项类型'}]"
                   readonly @click="showSkTypePicker = true"/>
        <van-popup v-model="showSkTypePicker" position="bottom">
            <van-picker show-toolbar :columns="skTypeList" value-key="Name" @confirm="onSkTypeConfirm" @cancel="showSkTypePicker = false" />
        </van-popup>

        <van-field label="回款户名" name="AccountName" placeholder="请输入回款户名" v-model="form.AccountName"
                   required :rules="[{required: true, message: '请输入回款户名'}]"/>
        <van-field label="回款账号" name="Account">
            <template #input>
                <!-- fix 清空后继续输入,不能弹出 -->
                <el-autocomplete v-model="form.Account" ref="accountAutocomplete" class="w-100x" placeholder="请输入回款账号" :fetch-suggestions="accountQuerySearch" clearable @clear="$refs.accountAutocomplete.handleFocus();$refs.accountAutocomplete.handleInput('')"></el-autocomplete>
            </template>
        </van-field>
        <van-field label="回款公司" name="GsN" placeholder="请选择回款公司" right-icon="arrow" :value="form.GsN"
                   required readonly @click="showComPicker = true" :rules="[{required: true, message: '请选择回款公司'}]"/>
        <van-popup v-model="showComPicker" position="bottom">
            <van-picker show-toolbar :columns="comList" value-key="Name" @confirm="onComConfirm" @cancel="showComPicker = false" />
        </van-popup>

        <van-field label="回款年度" name="YearMth" >
            <template #input>
                <van-stepper v-model="form.YearMth" integer input-width="64px"/>
            </template>
        </van-field>
        <van-field label="备注" placeholder="请输入备注" name="Remark" v-model="form.Remark" type="textarea" autosize>
            <template #right-icon>
                <van-icon name="plus" @click="showSkRemarkList = true"></van-icon>
            </template>
        </van-field>
        <van-action-sheet v-model="showSkRemarkList" :actions="skRemarkList" close-on-click-action @select="(item) => form.Remark = item.name"/>
    </van-form>

<!--    <div class="p-t-32 p-b-32 p-l-4 p-r-4">-->
    <div class="fixed to-left to-right to-bottom bg-white p-5 row content-around">
        <template v-if="!isEdit">
            <van-button type="info" round block class="w-30x" @click="save()">保存</van-button>
            <van-button type="danger" round block class="w-30x" @click="commit">提交</van-button>
        </template>
        <template v-else-if="status == 2 || status == 3">
            <van-button type="warning" block round class="w-30x" @click="del">删除</van-button>
            <van-button type="info" block round class="w-30x" @click="commit">修改</van-button>
            <van-button type="danger" block round class="w-30x" @click="commit">提交</van-button>
        </template>
        <!-- 审核中 -->
        <template v-else-if="status == 5">
            <van-button type="warning" round block @click="recall">撤回</van-button>
        </template>
    </div>


</div>
</template>

<script>
import api from "@/js/api";
import {Dialog, Notify} from 'vant';
export default {
    name: "huikuan-form",
    data(){
        // 最早为三年前
        let minDate = new Date()
        minDate.setFullYear(minDate.getFullYear() - 3)
        // 最晚为1年后
        let maxDate = new Date()
        maxDate.setFullYear(maxDate.getFullYear() + 1)
        let now = new Date()
        return {
            isEdit: false,
            status: 0,
            calenderMinDate: minDate,
            calenderMaxDate: maxDate,
            form: {
                BudgetSkID: 0,
                BillDate: now,
                SkMoney: '',
                FundSkaccountID: 0,
                SkaccountN: '',
                FundSktypeID: 0,
                SktypeN: '',
                AccountName: '',
                Account: '',
                FundGsInfoID: 0,
                GsN: '',
                YearMth: now.format('yyyy'),
                Remark: '',
                FundArtypeID: 0,
                ArtypeN: '',
            },
            rules: {
                BillDate: [{required: true, message: '请选择回款日期', trigger: ['blur', 'change']}],
                SkMoney: [{required: true, type: 'number', message: '请输入收款金额', trigger: ['blur', 'change']}],
                SkaccountN: [{required: true, message: '请选择收款账号', trigger: ['blur', 'change']}],
                SktypeN: [{required: true, message: '请选择款项类型', trigger: ['blur', 'change']}],
                AccountName: [{required: true, message: '请输入回款户名', trigger: ['blur', 'change']}],
                GsN: [{required: true, message: '请选择回款公司', trigger: ['blur', 'change']}],
            },
            showBillDate: false,
            showSkAccountPicker: false,
            skAccountList: [],
            showSkTypePicker: false,
            skTypeList: [],
            accountList: [],
            showComPicker: false,
            comList: [],
            showSkRemarkList: false,
            skRemarkList: [],
        }
    },
    methods: {
        getDetail(id, isCopy = false){
            api.getWebService('WebS_BusinessBudgetSk_G', this.vuex_emp_no, {BudgetSkID: id}).then(data => {
                // 处理数据
                let detail = data.Table[0]
                if(!detail) {
                    Notify('数据不存在或已删除')
                    setTimeout(() => {
                        this.$router.go(-1)
                    }, 2000)
                    return
                }
                this.form = {
                    BudgetSkID: detail.BudgetSkID,
                    BillDate: detail.BillDate ? new Date(detail.BillDate) : new Date(),
                    SkMoney: detail.SkMoney || '',
                    FundSkaccountID: detail.FundSkaccountID || 0,
                    SkaccountN: detail.SkaccountN || '',
                    FundSktypeID: detail.FundSktypeID || 0,
                    SktypeN: detail.SktypeN || '',
                    Account: detail.Account || '',
                    AccountName: detail.AccountName || '',
                    FundGsInfoID: detail.FundGsInfoID || 0,
                    GsN: detail.GsN || '',
                    YearMth: detail.YearMth || '',
                    Remark: detail.Remark || '',
                    FundArtypeID: detail.FundArtypeID || 0,
                    ArtypeN: detail.ArtypeN || '',
                }
                // 如果是复制操作, id为空
                if(isCopy) {
                    this.form.BudgetSkID = 0
                }
                this.status = detail.Status
            })
        },
        getSkAccountList(){
            api.getWebService('WebS_MasterSkaccount', this.vuex_emp_no).then(data => {
                this.skAccountList = data.Table
            })
        },
        getSkTypeList() {
            api.getWebService('WebS_MasterSktype', this.vuex_emp_no).then(data => {
                this.skTypeList = data.Table
            })
        },
        getAccountList(){
            api.getWebService('WebS_MasterAccount', this.vuex_emp_no).then(data => {
                this.accountList = data.Table.map(item => {
                    return {value: item.Account}
                })
            })
        },
        getComList(){
            api.getWebService('WebS_MasterGs', this.vuex_emp_no).then(data => {
                this.comList = data.Table
            })
        },
        getSkRemarkList(){
            api.getWebService('WebS_MasterBudgetSkRemark', this.vuex_emp_no).then(data => {
                this.skRemarkList = data.Table.map(item => {
                    return {name: item.Name}
                })
            })
        },
        onBillDateConfirm(e){
            this.form.BillDate = e
            this.showBillDate = false
        },
        onSkAccountConfirm(e){
            this.form.FundSkaccountID = e.FundSkaccountID
            this.form.SkaccountN = e.Name
            this.showSkAccountPicker = false
        },
        onSkTypeConfirm(e) {
            this.form.FundSktypeID = e.FundSktypeID
            this.form.SktypeN = e.Name
            this.showSkTypePicker = false
        },
        accountQuerySearch(queryString, cb){
            let list = queryString ? this.accountList.filter(item => item.value.toLowerCase().indexOf(queryString.toLowerCase()) >= 0) : this.accountList
            cb(list)
        },
        onComConfirm(e){
            this.form.FundGsInfoID = e.FundGsInfoID
            this.form.GsN = e.Name
            this.showComPicker = false
        },

        // 保存
        save(cb = false){
            this.$refs.vanForm.validate().then(() => {
                let service = 'WebS_BusinessBudgetSk_I'
                if(this.isEdit) service = 'WebS_BusinessBudgetSk_U'
                api.getWebService(service, this.vuex_emp_no, this.form).then(data => {
                    if(data.Code == 0) {
                        this.form.BudgetSkID = data.KeyNo
                        this.isEdit = true
                        if(!cb) {
                            Notify({type: 'success', message: '保存成功'})
                            setTimeout(() => {
                                this.$router.back()
                            }, 1000)
                        }else {
                            cb()
                        }
                    }else {
                        Notify({type: 'error', message: data.Message})
                    }
                })
            })
        },
        // 提交
        commit() {
            this.$refs.vanForm.validate().then(() => {
                // 如果是新增, 先保存数据,再提交
                if(!this.isEdit) {
                    this.save(this.commit)
                    return
                }
                // 提交数据
                api.getWebService('WebS_BusinessBudgetSk_Usubmit', this.vuex_emp_no, this.form).then(data => {
                    console.log(data, data.Code == 0)
                    if(data.Code == 0) {
                        this.form.BudgetSkID = data.KeyNo
                        // 提交成功
                        Notify({type: 'success', message: '提交成功'})
                        setTimeout(() => {
                            this.$router.back()
                        }, 1000)
                    }else {
                        Notify({type: 'error', message: data.Message})
                    }
                })
            })
        },
        // 删除
        del(){
            Dialog.confirm({
                title: '提示',
                message: '确定删除这条汇款记录吗?',
            }).then(() => {
                api.getWebService('WebS_BusinessBudgetSk_UDel', this.vuex_emp_no, {BudgetSkID: this.form.BudgetSkID}).then(data => {
                    if(data.Code == 0) {
                        Notify({type: 'success', message: '删除成功'})
                        setTimeout(() => {
                            this.$router.back()
                        }, 1000)
                    }else {
                        Notify({type: 'error', message: data.Message})
                    }
                })
            })
        },
        // 撤回
        recall() {
            api.getWebService('WebS_BusinessBudgetSk_Urecall', this.vuex_emp_no, {BudgetSkID: this.form.BudgetSkID}).then(data => {
                if(data.Code == 0) {
                    Notify({type: 'success', message: '撤回成功'})
                    this.getDetail(this.form.BudgetSkID)
                }else {
                    Notify({type: 'error', message: data.Message})
                }
            })
        }
    },
    created() {
        let id = this.$route.query.id
        let fromId = this.$route.query.fromId
        if(id) {
            // 编辑, 回显数据
            this.isEdit = true
            this.form.BudgetSkID = id
            this.getDetail(id)
            document.title = '回款详情'
        }else if(fromId) {
            this.getDetail(fromId, true)
            document.title = '复制回款'
        }else {
            document.title = '新增回款'
        }
        this.getSkAccountList()
        this.getSkTypeList()
        this.getAccountList()
        this.getComList()
        this.getSkRemarkList()
    }
}
</script>

<style lang="scss">
.van-field .el-input__inner {
    padding: 0;
    border: none;
    line-height: inherit;
    height: auto;
}
.van-field .el-input__icon {
    height: auto;
    line-height: inherit;
    font-size: 16px;
}
</style>
<style scoped>

</style>

<template>
<div class="p-b-80">
    <!-- 想要显示搜索按钮, 输入框必须在form表单内, 并且有action值-->
    <van-form action="/">
        <van-field name="startDate" label="回款日期" placeholder="请选择回款日期范围" readonly right-icon="arrow" :value="query.startDate ? (query.startDate.format('yyyy-MM-dd') + ' 至 ' + query.endDate.format('yyyy-MM-dd')) : ''" required @click="calenderShow = true"></van-field>

<!--        <van-field name="accountName" label="回款户名" placeholder="请输入回款户名" v-model="query.accountName" type="search" @keypress="onAccountNameKeypress"></van-field>-->
        <van-field name="artype" label="回款公司" placeholder="请选择回款公司" right-icon="arrow" readonly :value="query.GsN" @click="comPickerShow = true"></van-field>
        <van-field name="artype" label="应收类型" placeholder="请选择应收类型" right-icon="arrow" readonly :value="query.artypeName" @click="artypePickerShow = true"></van-field>

        <!--    状态栏tab-->
        <van-sticky>
            <van-tabs v-model="query.status" @change="statusTabChange">
                <van-tab v-for="(item, index) in statusList" :key="index" :title="item.name" :name="item.value"></van-tab>
            </van-tabs>
        </van-sticky>
    </van-form>
    <van-calendar v-model="calenderShow" type="range" :min-date="calenderMinDate" :max-date="calenderMaxDate" :default-date="[query.startDate, query.endDate]" @confirm="onCalenderConfirm" />
    <van-popup v-model="artypePickerShow" position="bottom">
        <van-picker show-toolbar :columns="artypeList" value-key="Name" cancel-button-text="清空" @confirm="onArtypeConfirm" @cancel="onArtypeCancel"/>
    </van-popup>
    <van-popup v-model="comPickerShow" position="bottom">
        <van-picker show-toolbar :columns="comList" value-key="Name" cancel-button-text="清空" @confirm="comConfirm" @cancel="comCancel"/>
    </van-popup>

<!--    列表-->
    <van-list
        v-model="loading"
        :finished="isLastPage"
        finished-text="没有更多了"
        @load="nextPage"
    >
        <div v-for="item in list" class="bg-white bor-1 bor-c-eee bor-ra-10 m-10 p-8 relative f-s-12 text-left bg-overlay" @click="toDetail(item)">
            <div class="font-bold f-s-14 m-b-10">{{item.BillDate}}</div>
            <div class="row content-between m-b-6">
                <div>收款金额: <span class="c-red">{{item.SkMoney}}</span></div>
                <div>{{item.ArtypeN}}</div>
            </div>

            <div class="m-b-6">回款公司: {{item.GsN}}</div>
            <div class="m-b-6">收款账号: {{item.SkaccountN}}</div>

            <!-- 状态 "Status 2=待提交，3=已驳回，5=审批中，13=已过账/已审批" -->
            <div v-if="item.Status == 2" class="absolute to-top to-right m-r-8 m-t-8 c-warning" >{{item.StatusN}}</div>
            <div v-else-if="item.Status == 3" class="absolute to-top to-right m-r-8 m-t-8 c-danger" >{{item.StatusN}}</div>
            <div v-else-if="item.Status == 5" class="absolute to-top to-right m-r-8 m-t-8 c-success" >{{item.StatusN}}</div>
            <div v-else class="absolute to-top to-right m-r-8 m-t-8 c-info" >{{item.StatusN}}</div>

            <!-- 复制按钮 status=13时存在 -->
            <div class="absolute to-bottom to-right m-r-8 m-b-8">
                <van-button size="small" v-if="item.Status == 13" @click.stop="copyData(item)">复制</van-button>
            </div>
        </div>
    </van-list>

<!--    底部按钮-->
    <div class="fixed to-left to-right to-bottom bg-white p-l-16 p-r-16 p-t-5 p-b-5">
        <van-button type="danger" block round @click="toAdd">添加</van-button>
    </div>
</div>
</template>

<script>
import api from "@/js/api";

export default {
    name: "huikuan-index",
    data(){
        // 最早为三年前
        let minDate = new Date()
        minDate.setFullYear(minDate.getFullYear() - 3)
        // 最晚为1年后
        let maxDate = new Date()
        maxDate.setFullYear(maxDate.getFullYear() + 1)
        let now = new Date()
        return {
            calenderShow: false,
            calenderMinDate: minDate,
            calenderMaxDate: maxDate,
            comPickerShow: false,
            comList: [],
            artypePickerShow: false,
            artypeList: [],
            statusList: [{name: '待提交', value: 2}, {name: '已驳回', value: 3}, {name: '审核中', value: 5}, {name: '已入账', value: 13}],
            query: {
                startDate: new Date(now.getTime() - 17 * 86400 * 1000),
                endDate: now,
                accountName: '',
                FundGsInfoID: null,
                GsN: '',
                artype: null,
                artypeName: '',
                status: 0,
                pageSize: 10,
                page: 1,
            },

            list: [],
            total: 0,
            moneyTotal: null,
            isLastPage: false,
            loading: false,
        }
    },
    methods: {
        getComList(){
            api.getWebService('WebS_MasterGs', this.vuex_emp_no).then(data => {
                this.comList = data.Table
            })
        },
        getArtypeList(){
            api.getWebService('WebS_MasterArtype', this.vuex_emp_no).then(data => {
                this.artypeList = data.Table
            })
        },
        onAccountNameKeypress(e){
            if(e.keyCode == 13) { // 确认按钮
                // 搜索点击
                this.relist()
            }
        },
        onCalenderConfirm(e){
            this.query.startDate = e[0]
            this.query.endDate = e[1]
            this.calenderShow = false
            this.relist()
        },
        comConfirm(value, index) {
            this.query.FundGsInfoID = value.FundGsInfoID
            this.query.GsN = value.Name
            this.comPickerShow = false
            this.relist()
        },
        comCancel() {
            this.query.FundGsInfoID = 0
            this.query.GsN = ''
            this.comPickerShow = false
            this.relist()
        },
        onArtypeConfirm(value, index) {
            this.query.artype = value.FundArtypeID
            this.query.artypeName = value.Name
            this.artypePickerShow = false
            this.relist()
        },
        onArtypeCancel() {
            this.query.artype = null
            this.query.artypeName = ''
            this.artypePickerShow = false
            this.relist()
        },
        statusTabChange(){
            this.relist()
        },
        relist(){
            this.query.page = 1
            this.list = []
            this.getList()
        },
        nextPage(){
            this.query.page ++
            this.getList()
        },
        getList(){
            // "EmpNO：16378
            // Jsonparam：{""StartDate"": ""2023-04-01"",""EndDate"": ""2023-04-10"",""AccountName"": """",""Artype"":0,""Status"":0,""pPageRowCount"": 10,""pPageIndex"": 1}"
            let params = {
                StartDate: this.query.startDate,
                EndDate: this.query.endDate,
                AccountName: this.query.accountName,
                FundGsInfoID: this.query.FundGsInfoID,
                Artype: this.query.artype || 0,
                Status: this.query.status,
                pPageRowCount: this.query.pageSize,
                pPageIndex: this.query.page,
            }
            this.loading = true
            api.postWebService('WebS_BusinessBudgetSk_FS', this.vuex_emp_no, params).then(data => {
                this.list.push(...data.Table)
                this.total = data.Table1[0].pPageTotal
                this.moneyTotal = data.Table1[0].SkMoneyTotal
                this.isLastPage = this.query.page * this.query.pageSize >= this.total
                this.loading = false
            })
        },
        toDetail(item) {
            this.$router.push('/tiantianquan/huikuan/form?id=' + item.BudgetSkID)
        },
        toAdd() {
            this.$router.push('/tiantianquan/huikuan/form')
        },
        copyData(item){
            this.$router.push('/tiantianquan/huikuan/form?fromId=' + item.BudgetSkID)
        }
    },
    created() {
        this.query.status = this.statusList[0].value
        this.getArtypeList()
        this.getComList()
        this.relist()
    }
}
</script>

<style scoped>

</style>

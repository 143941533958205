import * as Lockr from 'lockr'
Lockr.setPrefix('nps_')
window.Lockr = Lockr

// Date对象追加格式化方法
Date.prototype.format = function(fmt){
    if(!fmt){
        fmt = 'yyyy-MM-dd hh:mm:ss'
    }
    if(fmt === 'date') fmt = 'yyyy-MM-dd';
    let o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (let k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
};

function getType(obj){
    //tostring会返回对应不同的标签的构造函数
    let toString = Object.prototype.toString;
    let map = {
        '[object Boolean]'  : 'boolean',
        '[object Number]'   : 'number',
        '[object String]'   : 'string',
        '[object Function]' : 'function',
        '[object Array]'    : 'array',
        '[object Date]'     : 'date',
        '[object RegExp]'   : 'regExp',
        '[object Undefined]': 'undefined',
        '[object Null]'     : 'null',
        '[object Object]'   : 'object'
    };
    return map[toString.call(obj)];
}

window.deepCopy = function deepCopy(source){
    let type = getType(source);
    let obj;
    if(type === 'array'){
        obj = [];
        for(let i = 0, len = source.length; i < len; i++){
            obj.push(deepCopy(source[i]));
        }
    } else if(type === 'object'){
        obj = {};
        for(let key in source){
            obj[key] = deepCopy(source[key]);
        }
    } else {
        //不再具有下一层次
        return source;
    }
    return obj;
};

// 如果页面在iframe中, 跳到顶层页面
if(window.top !== window){
    window.top.location = window.location;
}


import axios from 'axios';

import {Notify} from 'vant';
import {DOMParser} from 'xmldom'

window.baseApiUrl = window.baseApiUrl || '/';
const CODE_OK = 200;
const CODE_OK_ALIAS = 1;
const CODE_UN_LOGIN = 10003;

class ResponseError {
    constructor(response){
        this.response = response;
    }
}

function handlerRes(res) {
    if (res && (res.code === CODE_OK || res.code === CODE_OK_ALIAS)) {
        return res.data;
    } else {
        return Promise.reject(new ResponseError(res));
    }
}

function handlerError(error) {
    if(error instanceof ResponseError){
        let res = error.response;
        if(res){
            switch (res.code) {
                case CODE_UN_LOGIN:
                    // store.commit('setUser', null)
                    // store.commit('setMenus', [])
                    // router.push('/login')

                    Notify('您的登录信息已失效, 请先登录');
                    break;
                default:
                    console.log('接口返回错误信息:', res, error);
                    if(!res.disableErrorMessage){
                        Notify(res.msg)
                    }
                    break;
            }
        }else {
            console.log('未知错误:', res);
        }
    }else {
        console.error('network error: ', error);
        Notify('请求超时，请检查网络')
    }
}

function getRealUrl(url) {
    if(url.indexOf(window.baseApiUrl) === 0){
        return url;
    }
    if(url.indexOf('/') === 0){
        url = url.substr(1);
    }
    return window.baseApiUrl + url
}

function get(url, params, defaultHandlerRes=true, headers = {}) {
    headers['X-Requested-With'] = 'XMLHttpRequest';
    let options = {
        headers: headers,
        params: params,
    };
    url = getRealUrl(url);
    let promise = axios.get(url, options).then(res => {
        // console.log(url, res)
        let result = res.data;
        if(defaultHandlerRes){
            return handlerRes(result);
        }else {
            return result;
        }
    });
    promise.catch(handlerError);
    return promise;
}

function post(url, params, defaultHandlerRes=true) {
    let options = {
        headers: {'X-Requested-With': 'XMLHttpRequest'},
        timeout: 1000 * 30,
    };
    url = getRealUrl(url);
    let promise = axios.post(url, params, options).then(res => {
        let result = res.data;
        if(defaultHandlerRes){
            return handlerRes(result);
        }else {
            return result;
        }
    });
    promise.catch(handlerError);
    return promise;
}

function mockData(data) {
    return new Promise((resolve, reject) => {
        resolve(data);
    })
}


function getWebService(apiName, empNo, params = {}) {
    let data = {EmpNO: empNo, Jsonparam: JSON.stringify(params)}
    let options = {
        params: data,
    };
    return axios.get('/NCextraAppWebS/APPWebService.asmx/' + apiName, options).then(res => {
        const doc = new DOMParser().parseFromString(res.data, 'text/xml')
        let returnStr = doc.getElementsByTagName('string')[0].childNodes[0].data
        return JSON.parse(returnStr)
    });
}
function postWebService(apiName, empNo, params = {}) {
    let options = {
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    };
    let data = {EmpNO: empNo, Jsonparam: JSON.stringify(params)}
    return axios.post('/NCextraAppWebS/APPWebService.asmx/' + apiName, data, options).then(res => {
        const doc = new DOMParser().parseFromString(res.data, 'text/xml')
        let returnStr = doc.getElementsByTagName('string')[0].childNodes[0].data
        return JSON.parse(returnStr)
    });
}

export default {
    get,
    post,
    mockData,
    handlerRes,
    getWebService,
    postWebService,
}
